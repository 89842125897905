import queryString from 'querystringify';
import { connectRoutes, NOT_FOUND, redirect } from 'redux-first-router';

import {
  ROUTER_CHANGELOG,
  ROUTER_DEFAULT,
  ROUTER_DOWNLOAD_REQUEST,
  ROUTER_EDIT_COMPONENTS,
  ROUTER_EDIT_NAVIGATION,
  ROUTER_EDIT_USERGROUPS,
  ROUTER_FAVOURITES,
  ROUTER_FORBIDDEN,
  ROUTER_ICON_LIBRARY,
  ROUTER_LOGIN,
  ROUTER_LOGOUT,
  ROUTER_MYFILES,
  ROUTER_PAGE,
  ROUTER_PAGE_WITH_ANCHOR,
  ROUTER_PAGE_WITH_ASSET,
  ROUTER_SEARCH,
  ROUTER_START,
  ROUTER_TRUCK_LABELING,
  ROUTER_WEB2PRINT,
} from '$const';
import { BASE_PATH as path } from '$env';

export type { Action, LocationState, Payload } from 'redux-first-router';

const getRoutesMap = () => {
  return {
    [ROUTER_START]: '/',
    [ROUTER_DEFAULT]: `/:lang?/${path}`,
    [ROUTER_LOGIN]: `/:lang?/${path}/login`,
    [ROUTER_LOGOUT]: '/user/logout',
    [ROUTER_ICON_LIBRARY]: `/:lang?/${path}/_/icon-library/:realm?`,
    [ROUTER_CHANGELOG]: `/:lang?/${path}/_/newsfeed`,
    [ROUTER_EDIT_COMPONENTS]: `/:lang?/${path}/edit/components`,
    [ROUTER_EDIT_NAVIGATION]: `/:lang?/${path}/edit/navigation`,
    [ROUTER_EDIT_USERGROUPS]: `/:lang?/${path}/edit/usergroups`,
    [ROUTER_FAVOURITES]: `/:lang?/${path}/_/favourites`,
    [ROUTER_MYFILES]: `/:lang?/${path}/_/myfiles`,
    [ROUTER_FORBIDDEN]: `/:lang?/${path}/_/forbidden`,
    [ROUTER_TRUCK_LABELING]: `/:lang?/${path}/_/truck-branding/:type?/:action?`,
    [ROUTER_WEB2PRINT]: `/:lang?/${path}/_/web2print`,
    [ROUTER_SEARCH]: `/:lang?/${path}/_/search/:term?`,
    [ROUTER_PAGE]: `/:lang?/${path}/:pageAlias`,
    [ROUTER_PAGE_WITH_ANCHOR]: `/:lang?/${path}/:pageAlias/:slug`,
    [ROUTER_PAGE_WITH_ASSET]: `/:lang?/${path}/:pageAlias/asset/:assetId`,
    [ROUTER_DOWNLOAD_REQUEST]: `/:lang?/${path}/_/download-request/:action/:token`,
  };
};

const navigateStart = () => ({
  type: ROUTER_START,
});
navigateStart.type = ROUTER_START;

const navigateToDefault = (payload?: { lang?: string }) => ({
  type: ROUTER_DEFAULT,
  payload,
});
navigateToDefault.type = ROUTER_DEFAULT;

const navigateToLogin = (payload?: unknown) => ({
  type: ROUTER_LOGIN,
  payload,
});
navigateToLogin.type = ROUTER_LOGIN;

const navigateToLogout = (payload?: unknown) => ({
  type: ROUTER_LOGOUT,
  payload,
});
navigateToLogout.type = ROUTER_LOGOUT;

const navigateToPage = (payload: { pageAlias: string; lang?: string }) => ({
  type: ROUTER_PAGE,
  payload,
});
navigateToPage.type = ROUTER_PAGE;

const navigateToPageWithAnchor = (payload: { pageAlias: string; lang?: string; slug: string }) => ({
  type: ROUTER_PAGE_WITH_ANCHOR,
  payload,
});
navigateToPageWithAnchor.type = ROUTER_PAGE_WITH_ANCHOR;

const navigateToPageWithAsset = (payload: { pageAlias: string; lang?: string; assetId: string }) => ({
  type: ROUTER_PAGE_WITH_ASSET,
  payload,
});
navigateToPageWithAsset.type = ROUTER_PAGE_WITH_ASSET;

const navigateToSearch = (payload: { term?: string; lang?: string }) => ({
  type: ROUTER_SEARCH,
  payload,
});
navigateToSearch.type = ROUTER_SEARCH;

const navigateToIconLibrary = (payload?: unknown) => ({
  type: ROUTER_ICON_LIBRARY,
  payload,
});
navigateToIconLibrary.type = ROUTER_ICON_LIBRARY;

const navigateToEditNavigation = (payload?: unknown) => ({
  type: ROUTER_EDIT_NAVIGATION,
  payload,
});
navigateToEditNavigation.type = ROUTER_EDIT_NAVIGATION;

const navigateToEditUsergroups = (payload?: unknown) => ({
  type: ROUTER_EDIT_USERGROUPS,
  payload,
});
navigateToEditUsergroups.type = ROUTER_EDIT_USERGROUPS;

const navigateToEditComponents = (payload?: unknown) => ({
  type: ROUTER_EDIT_COMPONENTS,
  payload,
});
navigateToEditComponents.type = ROUTER_EDIT_COMPONENTS;

const navigateToFavourites = (payload?: unknown) => ({
  type: ROUTER_FAVOURITES,
  payload,
});
navigateToFavourites.type = ROUTER_FAVOURITES;

const navigateToMyfiles = (payload?: unknown) => ({
  type: ROUTER_MYFILES,
  payload,
});
navigateToMyfiles.type = ROUTER_MYFILES;

const navigateToChangelog = (payload?: unknown) => ({
  type: ROUTER_CHANGELOG,
  payload,
});
navigateToChangelog.type = ROUTER_CHANGELOG;

const navigateToWeb2Print = (payload?: unknown) => ({
  type: ROUTER_WEB2PRINT,
  payload,
});
navigateToWeb2Print.type = ROUTER_WEB2PRINT;

const navigateToDownloadRequest = (payload: { action?: string; token?: string; lang?: string }) => ({
  type: ROUTER_DOWNLOAD_REQUEST,
  payload,
});
navigateToDownloadRequest.type = ROUTER_DOWNLOAD_REQUEST;

const notFound = (payload?: unknown) => ({
  type: NOT_FOUND,
  payload,
});
notFound.type = NOT_FOUND;

const forbidden = (payload?: unknown) => ({
  type: ROUTER_FORBIDDEN,
  payload,
});
forbidden.type = ROUTER_FORBIDDEN;

let router = connectRoutes(getRoutesMap(), {
  initialDispatch: false,
  querySerializer: queryString,
});

export let routerReducer = router.reducer;
export let routerMiddleware = router.middleware;
export let routerEnhancer = router.enhancer;
// eslint-disable-next-line @typescript-eslint/unbound-method
export let startRouting = router.initialDispatch;

export const buildRouter = () => {
  router = connectRoutes(getRoutesMap(), {
    initialDispatch: false,
    querySerializer: queryString,
  });
  routerReducer = router.reducer;
  routerMiddleware = router.middleware;
  routerEnhancer = router.enhancer;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  startRouting = router.initialDispatch;
};

//Holcim routes
const navigateToTruckLabeling = (payload?: unknown) => ({
  type: ROUTER_TRUCK_LABELING,
  payload,
});
navigateToTruckLabeling.type = ROUTER_TRUCK_LABELING;

// Export: Actions
export const actions = {
  redirect: redirect,
  notFound,
  forbidden,
  navigateStart,
  navigateToPage,
  navigateToPageWithAnchor,
  navigateToPageWithAsset,
  navigateToDefault,
  navigateToDownloadRequest,
  navigateToLogin,
  navigateToLogout,
  navigateToSearch,
  navigateToIconLibrary,
  navigateToEditComponents,
  navigateToEditNavigation,
  navigateToEditUsergroups,
  navigateToFavourites,
  navigateToMyfiles,
  navigateToChangelog,
  navigateToTruckLabeling,
  navigateToWeb2Print,
};

// Export: Reducer
export default routerReducer;
