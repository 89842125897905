import { combineReducers } from '@reduxjs/toolkit';

import TemplatesSlice from '~extension/truck-labeling/thunks/templates';
//Holcim Slices
import ConfigSlice from '../extension/truck-labeling/thunks/config';
import OrdersSlice from '../extension/truck-labeling/thunks/orders';
import UploadSlice from '../extension/truck-labeling/thunks/upload';
import { default as AddonsReducer } from './slices/addons';
import { default as ConfigReducer } from './slices/config';
import { default as ContextReducer } from './slices/context';
import { default as ControlReducer } from './slices/control';
import { default as CookieModalReducer } from './slices/cookieModal';
import { default as DraftEntitiesReducer } from './slices/draft-entities';
import { default as EntitiesReducer } from './slices/entities';
import { default as LoginReducer } from './slices/login';
import { default as MenuReducer } from './slices/menu';
import { default as ModalReducer } from './slices/modal';
import { buildRouter, default as RouterReducer } from './slices/router';
import { default as SessionReducer } from './slices/session';
import { default as StatusReducer } from './slices/status';
import { default as TranslationsReducer } from './slices/translations';
import { default as UserReducer } from './slices/user';
import { default as WidgetsFooterReducer } from './slices/widgetsFooter';
import { default as WidgetsSidebarReducer } from './slices/widgetsSidebar';

export const makeRootReducer = () => {
  buildRouter();

  return combineReducers({
    addons: AddonsReducer,
    config: ConfigReducer,
    context: ContextReducer,
    control: ControlReducer,
    cookieModal: CookieModalReducer,
    draftEntities: DraftEntitiesReducer,
    entities: EntitiesReducer,
    location: RouterReducer,
    login: LoginReducer,
    menu: MenuReducer,
    modal: ModalReducer,
    session: SessionReducer,
    status: StatusReducer,
    translations: TranslationsReducer,
    user: UserReducer,
    widgetsFooter: WidgetsFooterReducer,
    widgetsSidebar: WidgetsSidebarReducer,

    //truck labeling reducer
    truckLabelingConfig: ConfigSlice,
    truckLabelingTemplates: TemplatesSlice,
    truckLabelingOrders: OrdersSlice,
    truckLabelingUpload: UploadSlice,
  });
};
