/* Module Type Mappings */
export const ModuleTypes = {
  anchorLink: 'paragraph--anchor',
  internalLink: 'menu--element',
  nodePage: 'node--page',
  introduction: 'paragraph--introduction',
};

/* Modal Settings */
export const DeleteModalSettings = {
  type: 'confirm',
  headline: 'Please confirm',
  text: 'Do you really want to delete this item?',
  width: '460px',
  height: '260px',
  onConfirmationClick: null,
};

export const DeleteDraftSettings = {
  type: 'confirm',
  headline: 'Please confirm',
  text: 'Do you really want to delete this draft?',
  width: '460px',
  height: '260px',
  onConfirmationClick: null,
};

/* ROUTER routes definition */
export const ROUTER_START = 'router/start';
export const ROUTER_PAGE = 'router/navigateToPage';
export const ROUTER_PAGE_WITH_ANCHOR = 'router/navigateToPageWithAnchor';
export const ROUTER_PAGE_WITH_ASSET = 'router/navigateToPageWithAsset';
export const ROUTER_LOGIN = 'router/navigateToLogin';
export const ROUTER_LOGOUT = 'router/navigateToLogout';
export const ROUTER_DEFAULT = 'router/navigateToDefault';
export const ROUTER_SEARCH = 'router/navigateToSearch';
export const ROUTER_ICON_LIBRARY = 'router/navigateToIconLibrary';
export const ROUTER_EDIT_COMPONENTS = 'router/edit/components';
export const ROUTER_EDIT_NAVIGATION = 'router/edit/navigation';
export const ROUTER_EDIT_USERGROUPS = 'router/edit/usergroups';
export const ROUTER_FAVOURITES = 'router/favourites';
export const ROUTER_MYFILES = 'router/myfiles';
export const ROUTER_CHANGELOG = 'router/changelog';
export const ROUTER_FORBIDDEN = 'router/forbidden';
export const ROUTER_TRUCK_LABELING = 'router/truckLabeling';
export const ROUTER_WEB2PRINT = 'router/web2print';
export const ROUTER_DOWNLOAD_REQUEST = 'router/downloadRequest';

/* URL replacement */
export enum URL_REPLACEMENT {
  BACKEND_URL = '__backendUrl__',
  SYMFONY_URL = '__symfonyUrl__',
  API_BASE = '__apiBase__',
}

/* Anchors */
export const ANCHOR_PREFIX = 'anchor-';
export const ANCHOR_TOP_ID = `${ANCHOR_PREFIX}-top`;

/* Media-Library */
export const DEFAULT_MEDIA_LIBRARY_REALM = 'asset_library';
export const URL_PARAM_ASSETID = 'AssetId';
export const URL_PARAM_COLLECTION = 'Collection';
