import { any, array, assign, boolean, coerce, defaulted, Describe, number, optional, string, type } from 'superstruct';

const AddonBaseValidator: Describe<AddonBase> = defaulted(
  type({
    status: number(),
  }),
  {
    status: 0,
  },
);

const AddonParagraphValidator: Describe<AddonParagraph> = type({
  modules: array(string()),
  features: any(),
});

const AddonNewsfeedValidator: Describe<AddonNewsfeed> = optional(
  assign(
    AddonBaseValidator,
    type({
      news_count: coerce(number(), string(), val => parseInt(val, 10)),
      teaser: type({
        show_teaser: coerce(boolean(), number(), val => Boolean(val)),
        teaser_title: string(),
        teaser_text: string(),
        teaser_image: string(),
      }),
    }),
  ),
);

const AddonWeb2printValidator: Describe<AddonWeb2Print> = defaulted(
  type({
    status: number(),
    maintenance: boolean(),
  }),
  {
    maintenance: false,
    status: 0,
  },
);

const AddonFavouritesValidator: Describe<AddonFavourite> = defaulted(
  type({
    status: number(),
    defaultLibraryLink: string(),
  }),
  {
    status: 0,
    defaultLibraryLink: '/brandguide/media-library',
  },
);

const AddonJiraRequestWidgetValidator: Describe<AddonJiraRequestWidget> = defaulted(
  type({
    status: number(),
    src: string(),
    'data-key': string(),
    'data-base-url': string(),
  }),
  {
    status: 0,
    src: '',
    'data-key': '',
    'data-base-url': '',
  },
);

export const AddonsValidator: Describe<AddonsDto> = type({
  myFiles: optional(AddonBaseValidator),
  iconLibrary: AddonBaseValidator,
  paragraph: AddonParagraphValidator,
  pdf: AddonBaseValidator,
  publishing: AddonBaseValidator,
  favourites: AddonFavouritesValidator,
  changelog: AddonBaseValidator,
  newsfeed: AddonNewsfeedValidator,
  meta: optional(AddonBaseValidator),
  truckLabeling: optional(AddonBaseValidator),
  jiraRequestWidget: AddonJiraRequestWidgetValidator,
  web2print: AddonWeb2printValidator,
});
